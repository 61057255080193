import React from "react";
import styled from "styled-components";
import Contact from "../components/Contact";

const ClientSuccessStyle = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0px 100px;
    h1 {
        text-align: center;
        font-weight: bold;
        margin-top: 80px;
        margin-bottom: 40px;
    }
    .cs-hero {
        width: 100%;
        max-width: 880px;
        padding: 0 20px;
        img {
            width: 100%;
        }
    }
    .cs-cards-wrap {
        width: 100%;
        background-color: var(--lightGray);
        padding: 40px 20px;
        display: flex;
        justify-content: center;
        .cs-cards-row {
            width: 100%;
            max-width: 1080px;
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;
            .cs-card-item {
                width: 270px;
                padding: 20px 10px;
                .cs-card {
                    width: 100%;
                    height: 100%;
                    background-color: white;
                    border-radius: 10px;
                    padding: 20px;
                    .cs-card-pic {
                        width: 100%;
                        height: 148px;
                    }
                    h3 {
                        font-size: 20px;
                        font-weight: bold;
                        margin-bottom: 5px;
                        margin-top: 20px;
                    }
                    h4 {
                        color: var(--dark);
                    }
                }
                :nth-child(1) .cs-card-pic {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_g1.svg") center no-repeat;
                    background-size: contain;
                }
                :nth-child(2) .cs-card-pic {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_g2.svg") center no-repeat;
                    background-size: contain;
                }
                :nth-child(3) .cs-card-pic {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_g3.svg") center no-repeat;
                    background-size: contain;
                }
                :nth-child(4) .cs-card-pic {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_g4.svg") center no-repeat;
                    background-size: contain;
                }
                :nth-child(5) .cs-card-pic {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_g5.svg") center no-repeat;
                    background-size: contain;
                }
                :nth-child(6) .cs-card-pic {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_g6.svg") center no-repeat;
                    background-size: contain;
                }
                :nth-child(7) .cs-card-pic {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_g7.svg") center no-repeat;
                    background-size: contain;
                }
                :nth-child(8) .cs-card-pic {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_g8.svg") center no-repeat;
                    background-size: contain;
                }

                @media (max-width: 400px) {
                    width: 100%;
                }
            }
        }

        @media (max-width: 400px) {
            padding: 20px;
        }
    }
    .cs-library {
        width: 100%;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .cs-library-pic {
            width: 100%;
            max-width: 524px;
            margin-bottom: 60px;
            img { width: 100% }
        }
        .cs-library-cards {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: stretch;
            flex-wrap: wrap;
            .cs-library-card {
                width: 300px;
                padding: 20px 30px;
                .icon {
                    width: 80px;
                    height: 80px;
                    margin-bottom: 5px;
                }
                h3 {
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 5px;
                }
                h4 {
                    color: var(--dark);
                }
                :nth-child(1) .icon {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_icon1.svg") center no-repeat;
                    background-size: 80px;
                }
                :nth-child(2) .icon {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_icon2.svg") center no-repeat;
                    background-size: 80px;
                }
                :nth-child(3) .icon {
                    background: url("https://mtimg.onemiao.cn/web-images/cs/cs_icon3.svg") center no-repeat;
                    background-size: 80px;
                }
            }
        }
    }
`;

export default function ClientSuccess() {
    return <ClientSuccessStyle>
        <div className="cs-hero">
            <img src="https://mtimg.onemiao.cn/web-images/cs/cs_hero.png" alt="" />
            <h1>定制化实施流程</h1>
            <img src="https://mtimg.onemiao.cn/web-images/cs/cs_pic1.png" alt="" />
            <h1>客户成功服务特点</h1>
        </div>
        <div className="cs-cards-wrap">
            <div className="cs-cards-row">
                <div className="cs-card-item">
                    <div className="cs-card">
                        <div className="cs-card-pic"></div>
                        <h3>5个阶段<br />客户全生命周期服务</h3>
                        <h4>从用户触达、用户采购、需求梳理、交付实施、客户成功等5个阶段更好的帮助用户打造全生命周期无缝对接的专业服务。</h4>
                    </div>
                </div>
                <div className="cs-card-item">
                    <div className="cs-card">
                        <div className="cs-card-pic"></div>
                        <h3>1 对 N<br />专属 CSM 团队服务</h3>
                        <h4>遇到问题，就找专属的 CSM 服务团队对接人； 全程跟踪服务，再无后顾之忧，客户成功团队，专家顾问团队快速响应。</h4>
                    </div>
                </div>
                <div className="cs-card-item">
                    <div className="cs-card">
                        <div className="cs-card-pic"></div>
                        <h3>7*9 小时<br />线上多形式服务</h3>
                        <h4>客户成功团队快速响应，以专业的业务和服务能力做支撑。 全天候在线服务团队，客服7×9小时热线电话及企业微信远程协作支持，实时解决客户问题。</h4>
                    </div>
                </div>
                <div className="cs-card-item">
                    <div className="cs-card">
                        <div className="cs-card-pic"></div>
                        <h3>企业数据分析<br />与典型案例</h3>
                        <h4>提供企业应用功能使用数据报告，帮助企业分析产品落地使用实施情况； 定期输出同行业优秀应用案例，为企业提供专业的可替代销售管理方案。</h4>
                    </div>
                </div>
                <div className="cs-card-item">
                    <div className="cs-card">
                        <div className="cs-card-pic"></div>
                        <h3>苗通学院</h3>
                        <h4>CSM团队会利用文档、直播以及视频等方式，定期为客户提供学习资料，并举行线下培训课程，让客户在产品使用过程中游刃有余。</h4>
                    </div>
                </div>
                <div className="cs-card-item">
                    <div className="cs-card">
                        <div className="cs-card-pic"></div>
                        <h3>客户说</h3>
                        <h4>以线上直播形式提供产品使用解答，近距离沟通企业落地实施方案，互问互答，在交流分享中帮助企业管理员更好解决公司的使用问题。</h4>
                    </div>
                </div>
                <div className="cs-card-item">
                    <div className="cs-card">
                        <div className="cs-card-pic"></div>
                        <h3>企业联盟</h3>
                        <h4>企业联盟-为苗通客户打造合作企业联盟共赢圈，提供专属增值服务，实现需求和供应端优质资源对接，帮助更多企业实现合作。</h4>
                    </div>
                </div>
                <div className="cs-card-item">
                    <div className="cs-card">
                        <div className="cs-card-pic"></div>
                        <h3>KP会</h3>
                        <h4>定期邀请行业“关键人”，帮助客户解决业务困境和管理难点，针对企业管理中的具体问题，实际案例进行剖析探讨，从细节处帮助企业做好管理，有效规避风险，做好长期规划。</h4>
                    </div>
                </div>
            </div>
        </div>
        <div className="cs-library">
            <h1>CSM服务资源库</h1>
            <div className="cs-library-pic">
                <img src="https://mtimg.onemiao.cn/web-images/cs/cs_pic2.png" alt="" />
            </div>
            <div className="cs-library-cards">
                <div className="cs-library-card">
                    <div className="icon"></div>
                    <h3>通用知识库</h3>
                    <h4>通用知识库中沉淀了疫苗行业从业人员必备的知识内容。其中包括：国家政策解读、行业规范解读、公共卫生知识以及疫苗产品知识等。</h4>
                </div>
                <div className="cs-library-card">
                    <div className="icon"></div>
                    <h3>运营知识库</h3>
                    <h4>运营知识库中包含大量日常业务过程中各类问题的解决方案。其中包括：系统产品运营知识、线下POV运营知识以及用户流量端的各类运营知识。</h4>
                </div>
                <div className="cs-library-card">
                    <div className="icon"></div>
                    <h3>辅助工具库</h3>
                    <h4>各类运营活动需要的的智能化工具。例如：摇号工具、私域运营工具、抽奖工具、疫苗订阅工具、以及客服工具等。</h4>
                </div>
            </div>
        </div>
        <Contact product={'SaaS'} />
    </ClientSuccessStyle>
}